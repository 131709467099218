'use client';

import { CustomLink, Empty, ScrollAnimation, Section } from '@/ui';
import { Box, Divider, Flex, Grid, Loader, Skeleton } from '@mantine/core';
import { VectorMap } from '@south-paw/react-vector-maps';
import { useTranslations } from 'next-intl';
import { SVGProps, useEffect, useState } from 'react';
import useSWR from 'swr';
import classnames from './map.module.css';

type Layer = {
  id: string;
  d: string;
  name: string;
};

type Map = {
  id: string;
  name: string;
  viewBox: string;
  layers: Layer[];
  active: boolean;
};

type RegionInfo =
  | 'department'
  | 'title'
  | 'full_name'
  | 'address'
  | 'position_title'
  | 'phone'
  | 'email'
  | 'website'
  | 'reception_day'
  | 'image';

type RegionData = {
  region_title: string;
  title: string;
  status: boolean;
  info: {
    [key in RegionInfo]: string;
  };
};

type ListProps = {
  list: string[];
  regionData: RegionData;
};

const firstList = ['position_title', 'address'];
const secondList = ['phone', 'website', 'reception_day'];
const titles: {
  [key: string]: string;
} = {
  position_title: 'Lavozim',
  address: 'Manzil',
  phone: 'Telefon',
  website: 'Veb-sayt',
  reception_day: 'Qabul kunlari',
};

const List = ({ list, regionData }: ListProps) => {
  const t = useTranslations();
  return (
    <ul>
      {list?.map((item) => {
        if (item === 'phone') {
          return regionData?.info?.['phone']?.trim() ? (
            <li key="phone">
              <h4>{t(titles['phone'])}:</h4>
              <Flex gap={2}>
                {regionData?.info?.['phone']?.split(',')?.map((phone) => (
                  <CustomLink key={phone} href={`tel:${phone}`} target="_blank">
                    {phone};
                  </CustomLink>
                ))}
              </Flex>
            </li>
          ) : null;
        }

        if (item === 'website') {
          return regionData?.info?.['website']?.trim() ? (
            <li key="website">
              <h4>{t(titles['website'])}:</h4>
              <CustomLink href={regionData?.info?.website || '/'} target="_blank">
                {regionData?.info?.website}
              </CustomLink>
            </li>
          ) : null;
        }

        return regionData?.info?.[item as RegionInfo]?.trim() ? (
          <li key={item}>
            <h4>{t(titles[item])}:</h4>
            <h4>{regionData?.info?.[item as RegionInfo]}</h4>
          </li>
        ) : null;
      })}
    </ul>
  );
};

export function Map() {
  const [hovered, setHovered] = useState('');
  const [clicked, setClicked] = useState('');
  const [activeId, setActiveId] = useState('');

  const { data: map, isLoading: isMapLoading } = useSWR<Map>(['/authorities/main/get-map']);

  const { data: region, isLoading: isRegionLoading } = useSWR<RegionData>([
    '/authorities/main/get-regional-info',
    { params: { 'region-code': activeId } },
  ]);

  const layerProps: SVGProps<SVGPathElement> = {
    onMouseEnter: (e) => setHovered(e.currentTarget.ariaLabel || ''),
    onMouseLeave: () => setHovered(''),
    onClick: (e) => {
      setClicked(e.currentTarget.ariaLabel || '');
      setActiveId(e.currentTarget.id || '');
    },
  };

  useEffect(() => {
    if (!map) return;

    setClicked(map?.layers?.[0]?.name);
    setActiveId(map?.layers?.[0]?.id);
  }, [map]);

  return isMapLoading ? (
    <Skeleton h={600} my="5rem" />
  ) : map?.active ? (
    <Section>
      <Grid gutter="lg" py="5rem">
        <Grid.Col span={{ base: 12, xl: 6, '3xl': 7 }}>
          <Box className={classnames.map}>
            <ScrollAnimation>
              <VectorMap
                id={map?.id}
                name={map?.name}
                layers={map?.layers}
                viewBox={map?.viewBox}
                layerProps={layerProps}
                checkedLayers={[activeId]}
                tabIndex={-1}
              />
              <span>{hovered || clicked}</span>
            </ScrollAnimation>
          </Box>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xl: 6, '3xl': 5 }}>
          <Box className={classnames.region}>
            {isRegionLoading ? (
              <Flex w="100%" h="100%" mih="20rem" justify="center" align="center">
                <Loader color="primary" size="lg" />
              </Flex>
            ) : (
              <ScrollAnimation className="relative z-10">
                <h3 className={classnames.region__title}>{region?.title}</h3>
                {region?.status ? (
                  <>
                    <h4 className={classnames.region__name}>{region?.info?.department}</h4>
                    <Divider my="md" color="primary" size={2} w="5rem" />
                    <Box className={classnames.region__info}>
                      <h3>{region?.info?.full_name}</h3>
                      <List list={firstList} regionData={region} />
                      <List list={secondList} regionData={region} />
                      {region?.info?.email?.trim() ? (
                        <CustomLink
                          href={`mailto:${region?.info?.email}`}
                          target="_blank"
                          className={classnames.region__email}
                        >
                          {region?.info?.email}
                        </CustomLink>
                      ) : null}
                    </Box>
                  </>
                ) : (
                  <Empty className="h-full" />
                )}
              </ScrollAnimation>
            )}
          </Box>
        </Grid.Col>
      </Grid>
    </Section>
  ) : null;
}
