'use client';

import { createURL } from '@/lib/utils';
import { Empty, LinkBox, ScrollAnimation } from '@/ui';
import { InfoContent } from '@/widgets';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';

type Service = {
  id: number;
  title: string;
  url: string;
};

export default function Services() {
  const t = useTranslations();
  const { data: services, isLoading } = useSWR<{ data: Service[] }>(['/authorities/main/interactive-services']);

  return (
    <InfoContent
      title={t('Interaktiv xizmatlar')}
      details={{
        title: t('Barcha xizmatlar'),
        link: createURL(services?.data?.[0]?.url || '/'),
        target: services?.data?.[0]?.url?.includes('http') ? '_blank' : '_self',
      }}
      data-bordered="1"
    >
      {isLoading ? (
        <LinkBox.Loading count={4} />
      ) : services?.data && services?.data?.length > 0 ? (
        services?.data?.map((service) => (
          <ScrollAnimation key={service?.id} className="basis-20">
            <LinkBox url={createURL(service?.url)} target={service?.url?.includes('http') ? '_blank' : '_self'}>
              {service?.title}
            </LinkBox>
          </ScrollAnimation>
        ))
      ) : (
        <Empty />
      )}
    </InfoContent>
  );
}
