'use client';

import { routing } from '@/i18n/routing';
import { createURL } from '@/lib/utils';
import { Empty, LinkBox, ScrollAnimation } from '@/ui';
import { InfoContent } from '@/widgets';
import { useLocale, useTranslations } from 'next-intl';
import useSWR from 'swr';

type VacancyT = { salary: number; url: string } & {
  [key: string]: string;
};

type VacanciesData = {
  data: VacancyT[];
  url: string;
};

const titles: {
  [key: string]: string;
} = {
  oz: 'title_oz',
  uz: 'title_uz',
  qr: 'title_qr',
  en: 'title_en',
  ru: 'title_ru',
};

export default function Vacancies() {
  const t = useTranslations();
  const locale = useLocale();

  const { data: vacancies, isLoading } = useSWR<VacanciesData>(['/authorities/main/get-vacancies']);

  const title = t('Bo‘sh ish o‘rinlari');

  return (
    <InfoContent title={title} details={{ title, link: vacancies?.url || '', target: '_blank' }} data-bordered="1">
      {isLoading ? (
        <LinkBox.Loading count={5} />
      ) : vacancies?.data && vacancies?.data?.length > 0 ? (
        vacancies?.data?.map((vacancy) => (
          <ScrollAnimation key={vacancy?.url} className="basis-20">
            <LinkBox url={createURL(vacancy?.url)} target="_blank">
              {vacancy?.[titles[locale || routing.defaultLocale]]}
            </LinkBox>{' '}
          </ScrollAnimation>
        ))
      ) : (
        <Empty />
      )}
    </InfoContent>
  );
}
