'use client';

import { createURL } from '@/lib/utils';
import { LinkBox, ScrollAnimation, Section } from '@/ui';
import { Box } from '@mantine/core';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';
import classnames from './activities.module.css';

type Activity = {
  id: number;
  code: string;
  title: string;
};

type Activities = {
  data: Activity[];
  sub_link: string;
};

const className = 'py-4 h-20 capitalize';

export function Activities() {
  const t = useTranslations();

  const { data: activities, isLoading } = useSWR<Activities>(['/authorities/activity/get-activity-list']);

  if (!isLoading && activities && activities?.data?.length < 1) return null;

  return (
    <Section
      title={t('Faoliyatlar yo‘nalishi')}
      details={{
        link: createURL(activities?.sub_link || '/'),
        title: t('Batafsil'),
      }}
    >
      <Box className={classnames.activities}>
        {isLoading ? (
          <LinkBox.Loading count={6} className={className} />
        ) : (
          activities?.data?.slice(0, 9)?.map((activity, i) => (
            <ScrollAnimation key={activity?.id} delay={i * 0.05} className="h-20">
              <LinkBox url={`/activity_page/${activity?.code}`} className={className}>
                {activity?.title}
              </LinkBox>
            </ScrollAnimation>
          ))
        )}
      </Box>
    </Section>
  );
}
