import { useHeaderStore } from '@/lib/store';
import { MasonryOptions, Menu as MenuT, StringList } from '@/lib/types';
import { createURL, NAVBAR_Z_INDEX } from '@/lib/utils';
import { CustomLink, Masonry } from '@/ui';
import { Box, Burger, Menu, Title, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { clsx } from 'clsx';
import { useTranslations } from 'next-intl';
import { forwardRef } from 'react';
import classnames from './header.module.css';

type Props = { data: MenuT[]; isMobile?: boolean };

const sitemapBreakpoints: MasonryOptions = {
  default: 1,
  breakpoints: { '4xl': 5, '2xl': 4, lg: 3, md: 2 },
};

interface MenuTargetProps extends React.ComponentPropsWithoutRef<'button'> {
  opened: boolean;
  classNames: StringList;
  isMobile: boolean;
}

const MenuTarget = forwardRef<HTMLDivElement, MenuTargetProps>(function MenuTarget(props: MenuTargetProps, ref) {
  const t = useTranslations();
  const { isMobile, ...others } = props;

  return (
    <Box ref={ref} className={clsx({ [classnames.sitemap__mobile__target]: isMobile })}>
      <Tooltip
        label={t('Sayt xaritasi')}
        zIndex={NAVBAR_Z_INDEX + 1}
        position="bottom"
        color="primary"
        withArrow={true}
        arrowSize={5}
        maw={220}
        multiline={true}
        className="text-center"
        visibleFrom="lg"
        events={{ hover: true, focus: true, touch: false }}
      >
        <Burger aria-label={t('Sayt xaritasi')} {...others} />
      </Tooltip>
    </Box>
  );
});

export default function Sitemap({ data, isMobile = false }: Props) {
  const t = useTranslations();
  const headerHeight = useHeaderStore((store) => store.height);
  const [opened, { toggle, open, close }] = useDisclosure();

  const sitemapHeight = `calc(100vh - ${headerHeight}px)`;

  return (
    <>
      <Menu
        opened={opened}
        onOpen={open}
        onClose={close}
        offset={0}
        withinPortal={false}
        middlewares={{ shift: true, flip: false }}
      >
        <Menu.Target>
          <MenuTarget
            opened={opened}
            onClick={toggle}
            classNames={{
              root: clsx(classnames.sitemap__btn, { [classnames.mobile]: isMobile }),
              burger: classnames.sitemap__burger,
            }}
            isMobile={isMobile}
          />
        </Menu.Target>
        <Menu.Dropdown
          bg="primary"
          classNames={{ dropdown: clsx(classnames.sitemap__dropdown) }}
          style={{ maxHeight: sitemapHeight }}
        >
          <Title order={4} c="#fff" ta="center" mb="xl">
            {t('Sayt xaritasi')}
          </Title>
          <Masonry columns={sitemapBreakpoints}>
            {data?.map((item) => (
              <SitemapItem item={item} key={item?.id} />
            ))}
          </Masonry>
        </Menu.Dropdown>
      </Menu>
    </>
  );
}

const SitemapItem = ({ item }: { item: MenuT }) => {
  return (
    <div className={classnames.sitemap__item}>
      <h3>- {item.title}</h3>
      <ul>
        {item?.children
          ?.filter((child) => !!child?.url)
          ?.map((child) => (
            <li key={child?.id}>
              <CustomLink href={createURL(child?.url)} target={child?.target}>
                {child?.title}
              </CustomLink>
            </li>
          ))}
      </ul>
    </div>
  );
};
