import { SearchInput } from '@/components/global';
import { ENDPOINT, fetcher, removeDuplicates } from '@/lib/utils';
import { getLocalesFx } from '@/shared/services';
import HeaderView from './HeaderView';

export async function Header({ locale }: { locale: string }) {
  const infoData = fetcher(`${ENDPOINT}/authorities/main/authority-info`, locale);
  const sitemapData = fetcher(`${ENDPOINT}/authorities/main/get-main-menu`, locale);
  const suggestionsData = fetcher(`${ENDPOINT}/authorities/main/get-suggestions`, locale);
  const localesData = getLocalesFx();

  const [info, sitemap, suggestions, locales] = await Promise.all([
    infoData,
    sitemapData,
    suggestionsData,
    localesData,
  ]);

  return (
    <HeaderView info={info} sitemap={sitemap} locale={locale} locales={locales}>
      <SearchInput data={removeDuplicates(suggestions)} placeholder="Qidiruv" withinModal />
    </HeaderView>
  );
}
