'use client';

import { Target } from '@/lib/types';
import { CustomLink, Section, SliderArrow } from '@/ui';
import { Skeleton } from '@mantine/core';
import Image from 'next/image';
import Slider, { Settings } from 'react-slick';
import useSWR from 'swr';
import classnames from './banners.module.css';

type Banner = {
  id: number;
  title: string;
  target: Target;
  url: string;
  icon: string;
  count: string;
};

export function Banners() {
  const { data: banners, isLoading } = useSWR<Banner[]>(['/authorities/main/get-banner']);

  const carouselSettings: Settings = {
    className: classnames.carousel,
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    speed: 4000,
    autoplaySpeed: 5000,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow classList={classnames.carousel__prevBtn} />,
    nextArrow: <SliderArrow classList={classnames.carousel__nextBtn} />,
    accessibility: false,
  };

  if (isLoading) {
    return (
      <Section className={classnames.banners}>
        <Skeleton width="100%" radius="0" className={classnames.loading} />
      </Section>
    );
  }

  return banners && banners?.length > 0 ? (
    <Section className={classnames.banners}>
      <Slider {...carouselSettings}>
        {banners?.map((banner) =>
          banner?.icon ? (
            <CustomLink
              key={banner?.id}
              href={banner?.url || '/'}
              target={banner?.target}
              className={classnames.carousel__item}
            >
              <Image key={banner?.id} src={banner?.icon} width={1920} height={180} alt={banner?.title} />
            </CustomLink>
          ) : null
        )}
      </Slider>
    </Section>
  ) : null;
}
