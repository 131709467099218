import { AUTHORITY_CODE, ENDPOINT } from '@/lib/utils';
import axios from 'axios';

export async function getLocalesFx() {
  try {
    const { data } = await axios.get(`${ENDPOINT}/main-page/get-langs`, {
      headers: { code: AUTHORITY_CODE },
    });

    return data;
  } catch (e) {
    console.log(e);
  }
}
