'use client';

import { SocialMediaLinks } from '@/components/authority';
import { News } from '@/lib/types';
import { CalendarLink, DetailsLink, ScrollAnimation, ThreeStack, Title } from '@/ui';
import { Flex } from '@mantine/core';
import { useTranslations } from 'next-intl';
import dynamic from 'next/dynamic';
import useSWR from 'swr';
const NewsCard = dynamic(() => import('@/widgets').then((mod) => mod.NewsCard));
const NewsCardLoading = dynamic(() => import('@/widgets').then((mod) => mod.NewsCard.Loading));

export function NewsSection() {
  const t = useTranslations();

  const { data: news, isLoading } = useSWR<{ data: News[] }>([
    '/authorities/news/get-news-by-condition',
    { params: { limit: 7 } },
  ]);

  if (isLoading) {
    return (
      <section className="pt-3 pb-5 3xl:pb-3">
        <Flex
          direction={{ base: 'column-reverse', md: 'row' }}
          justify="space-between"
          align={{ base: 'flex-start', md: 'center' }}
          gap="lg"
          className="pb-6"
        >
          <Title text={t('Yangiliklar')} />
          <SocialMediaLinks />
        </Flex>
        <ThreeStack flexible className="gap-10">
          <NewsCardLoading />
          <Flex direction="column">
            <CalendarLink.Loading withoutBorder className="px-0" />
          </Flex>
        </ThreeStack>
        <div className="block text-center pt-5">
          <DetailsLink link="/news/news" title={t('Barcha yangiliklar')} />
        </div>
      </section>
    );
  }

  return news?.data && news?.data?.length > 0 ? (
    <section className="pt-3 pb-5 3xl:pb-3" id="main-content">
      <Flex
        direction={{ base: 'column-reverse', md: 'row' }}
        justify="space-between"
        align={{ base: 'flex-start', md: 'center' }}
        gap="lg"
        className="pb-6"
      >
        <Title text={t('Yangiliklar')} />
        <SocialMediaLinks />
      </Flex>
      <ThreeStack flexible className="gap-10">
        {news?.data?.slice(0, 2)?.map((item) => (
          <ScrollAnimation key={item?.id}>
            <NewsCard {...item} url={`/news/view/${item.id}`} />
          </ScrollAnimation>
        ))}
        <Flex direction="column">
          {news?.data?.slice(2, 7)?.map((item) => (
            <ScrollAnimation key={item?.id} className="h-full basis-20">
              <CalendarLink key={item?.id} className="px-0" withoutBorder url={`/news/view/${item?.id}`} {...item} />
            </ScrollAnimation>
          ))}
        </Flex>
      </ThreeStack>
      <div className="block text-center pt-5">
        <DetailsLink link="/news/news" title={t('Barcha yangiliklar')} />
      </div>
    </section>
  ) : null;
}
