import { usePathname } from '@/i18n/routing';
import { NAVBAR_Z_INDEX } from '@/lib/utils';
import { Modal } from '@mantine/core';
import { useDidUpdate, useWindowEvent } from '@mantine/hooks';
import { useSearchParams } from 'next/navigation';
import classnames from './header.module.css';

type Props = {
  opened: boolean;
  close: () => void;
  children: React.ReactNode;
};

export default function HeaderSearch({ opened, close, children }: Props) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useWindowEvent('keydown', (event) => {
    if (opened && event.code === 'Escape') {
      event.preventDefault();
      close();
    }
  });

  useDidUpdate(() => {
    if (opened) close();
  }, [pathname, searchParams.toString()]);

  return (
    <Modal
      zIndex={NAVBAR_Z_INDEX + 1}
      size="lg"
      withCloseButton={false}
      opened={opened}
      onClose={close}
      classNames={{ content: classnames.modal__content, body: classnames.modal__body }}
    >
      {children}
    </Modal>
  );
}
