'use client';

import { News } from '@/lib/types';
import { createURL } from '@/lib/utils';
import { CalendarLink, Empty, ScrollAnimation } from '@/ui';
import { InfoContent } from '@/widgets';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';

type Events = { data: News[]; url: string };

export default function Events() {
  const t = useTranslations();

  const { data: events, isLoading } = useSWR<Events>(['/authorities/news/event-schedule', { params: { limit: 4 } }]);

  return (
    <InfoContent
      title={t('Voqealar taqvimi')}
      details={{
        title: t('Barcha voqealar'),
        link: createURL(events?.url || '/'),
      }}
      data-bordered="2"
    >
      {isLoading ? (
        <CalendarLink.Loading count={4} />
      ) : events?.data && events?.data?.length > 0 ? (
        events?.data?.map((event) => (
          <ScrollAnimation key={event?.id} className="basis-20">
            <CalendarLink url={`/news/view/${event?.id}`} {...event} />{' '}
          </ScrollAnimation>
        ))
      ) : (
        <Empty />
      )}
    </InfoContent>
  );
}
