'use client';

import { Section, ThreeStack } from '@/ui';
import Events from './Events';
import Services from './Services';
import UsefulLinks from './UsefulLinks';

export function ServiceEventLink() {
  return (
    <Section>
      <ThreeStack>
        <Services />
        <Events />
        <UsefulLinks />
      </ThreeStack>
    </Section>
  );
}
