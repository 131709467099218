'use client';

import { RefreshIcon } from '@/icons';
import { useTouchAvailable } from '@/lib/hooks';
import { AuthorityInfo, Menu } from '@/lib/types';
import { CustomLink, SocialMediaIcon } from '@/ui';
import { Container, Flex, Grid, Skeleton } from '@mantine/core';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import useSWR from 'swr';
import Links from './Links';

type Props = {
  locale: string;
};

export function Footer({ locale }: Props) {
  const t = useTranslations();
  const isTouchAvailable = useTouchAvailable();

  const { data: info, isLoading } = useSWR<AuthorityInfo>(['/authorities/main/authority-info']);

  const { data: links } = useSWR<Menu[]>(['/authorities/main/get-footer-menu']);

  return isLoading ? (
    <Skeleton h={380} />
  ) : (
    <footer>
      <Container>
        <Links links={links || []} />
        <Flex direction="column" justify="center" gap="xl" pt="xl">
          <Grid gutter="lg" justify="center" align="center">
            <Grid.Col span={{ base: 12, lg: 6, xl: 4 }}>
              <Flex direction="column" justify="center" align={{ base: 'center', lg: 'flex-start' }} gap="sm">
                <CustomLink
                  href="/"
                  className="font--md font-medium uppercase max-w-xl lg:mx-auto lg:block lg:text-center lg:capitalize"
                >
                  {info?.title}
                </CustomLink>
                <p className="font--sm">{info?.address}</p>
              </Flex>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 6, xl: 4 }}>
              <Flex direction="column" justify="center" align="center" gap="lg">
                <CustomLink
                  href={`https://uzinfocom.uz/${locale != 'qr' && locale != 'oz' ? locale : 'uz'}`}
                  target="_blank"
                  className="w-max mx-auto"
                >
                  <Image src="/img/UZINFOCOM.svg" alt="UZINFOCOM" width={152} height={16} />
                </CustomLink>
                <p className="font--sm text-center max-w-xs">
                  © 2001-{new Date().getFullYear()} {t('Barcha huquqlar himoyalangan')}
                </p>
              </Flex>
            </Grid.Col>
            <Grid.Col span={{ base: 6, xl: 4 }}>
              <Flex direction="column" justify="center" align={{ base: 'center', xl: 'flex-end' }} gap="sm">
                <Flex justify="center" gap="md">
                  {info?.socials?.value && info?.socials?.value?.length > 0
                    ? info?.socials?.value?.map((social) => (
                        <SocialMediaIcon
                          key={social?.type}
                          url={social?.link}
                          type={social?.type}
                          variant="primary"
                          circle
                        />
                      ))
                    : null}
                </Flex>
                <div id="www_counter_view"></div>
              </Flex>
            </Grid.Col>
          </Grid>
          {info?.last_updated_date ? (
            <Flex justify="center" align="center" gap="sm">
              <RefreshIcon />
              <p className="font--sm text-center">
                {t('Oxirgi yangilanish')}: {info?.last_updated_date}
              </p>
            </Flex>
          ) : null}
          <p className="font--sm text-center">
            {isTouchAvailable
              ? t(
                  'Diqqat! Agar siz matnda xatoliklarni aniqlasangiz, ma’muriyatni xabardor qilish uchun ularni belgilab, pastda hosil bo‘ladigan tugmani bosing'
                )
              : t(
                  "Diqqat! Agar siz matnda xatoliklarni aniqlasangiz, ularni belgilab, ma'muriyatni xabardor qilish uchun Ctrl+Enter tugmalarini bosing"
                )?.replace('Ctrl', 'Ctrl/Command')}
          </p>
        </Flex>
      </Container>
    </footer>
  );
}
