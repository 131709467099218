'use client';

import { useTranslations } from 'next-intl';
import classnames from './marquee.module.css';

export function Marquee() {
  const t = useTranslations();

  return (
    <div className={classnames.marquee}>
      <p>{t('Platforma sinov tariqasida ishga tushirilgan')}</p>
    </div>
  );
}
