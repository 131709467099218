'use client';

import { CustomLink } from '@/ui';
import { Skeleton } from '@mantine/core';
import useSWR from 'swr';
import classnames from './inner-marquee.module.css';

type Data = {
  id: number;
  category_id: number;
  title: string;
  target: string;
  url: string;
  icon: string;
}[];

export function InnerMarquee() {
  const { data, isLoading } = useSWR<Data>(['/authorities/main/get-runner']);

  return isLoading ? (
    <Skeleton h={34} radius={0} />
  ) : data && data?.length > 0 ? (
    <div className={classnames.marquee}>
      <div>
        {data?.map((item) =>
          item?.url?.trim() ? (
            <CustomLink key={item?.id} href={item?.url || '/'} target={item?.target || '_blank'}>
              {item?.title}
            </CustomLink>
          ) : (
            <p key={item?.id}>{item?.title}</p>
          )
        )}
      </div>
    </div>
  ) : null;
}
