'use client';

import { SocialLink } from '@/lib/types';
import { createRandomId } from '@/lib/utils';
import { SocialMediaIcon } from '@/ui';
import { Flex, Skeleton } from '@mantine/core';
import useSWR from 'swr';

export function SocialMediaLinks() {
  const { data, isLoading } = useSWR<{ value: SocialLink[] }>(['/authorities/main/get-socials']);

  return (
    <Flex
      w={{ base: '100%', md: 'auto' }}
      gap="md"
      align="center"
      justify={{ base: 'center', md: 'flex-start' }}
      wrap="wrap"
    >
      {isLoading
        ? Array.from({ length: 5 }, () => <Skeleton key={createRandomId()} width={32} height={32} circle />)
        : data?.value?.map((item) => (
            <SocialMediaIcon
              key={item?.link}
              type={item?.type}
              url={item?.link}
              variant="secondary"
              position="bottom"
              circle
            />
          ))}
    </Flex>
  );
}
