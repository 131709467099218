'use client';

import { Documents } from '@/components/global';
import { Section, ThreeStack } from '@/ui';
import { Container } from '@mantine/core';
import Polls from './Polls';
import Vacancies from './Vacancies';

export function VacDocPoll() {
  return (
    <Section withShadow>
      <Container>
        <ThreeStack>
          <Vacancies />
          <Documents />
          <Polls />
        </ThreeStack>
      </Container>
    </Section>
  );
}
