'use client';

import { UsefulLink } from '@/lib/types';
import { CustomLink, Section, SliderArrow } from '@/ui';
import { Box, Skeleton } from '@mantine/core';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import Slider, { Settings } from 'react-slick';
import useSWR from 'swr';
import classnames from './projects.module.css';

export function Projects() {
  const t = useTranslations();

  const { data: projects, isLoading } = useSWR<{ data: UsefulLink[] }>(['/authorities/main/get-projects']);

  const slidesLength = projects?.data?.length || 0;

  const carouselSettings: Settings = {
    className: classnames.carousel,
    infinite: true,
    autoplay: true,
    pauseOnHover: true,
    speed: 2000,
    autoplaySpeed: 4000,
    draggable: false,
    prevArrow: <SliderArrow classList={classnames.carousel__prevBtn} />,
    nextArrow: <SliderArrow classList={classnames.carousel__nextBtn} />,
    slidesToShow: slidesLength > 2 ? 3 : 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: slidesLength > 1 ? 2 : 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
    accessibility: false,
  };

  return isLoading ? (
    <Section title={t('Amalga oshirilayotgan loyihalar')}>
      <Skeleton w="100%" h={100} radius="0" />
    </Section>
  ) : projects?.data && projects?.data?.length > 0 ? (
    <Section title={t('Amalga oshirilayotgan loyihalar')}>
      <Slider {...carouselSettings}>
        {projects?.data?.map((project) => (
          <Box key={project?.id} className={classnames.carousel__item}>
            <CustomLink
              href={project?.url || '/'}
              target={project?.target}
              className="flex items-center justify-center gap-5"
              tabIndex={-1}
            >
              {project?.icon ? <Image src={project?.icon} width={80} height={70} alt="" /> : null}
              {project?.title}
            </CustomLink>
          </Box>
        ))}
      </Slider>
    </Section>
  ) : null;
}
