import { ENDPOINT } from '@/lib/utils';
import axios from 'axios';

export async function getMenuFx(locale: string = 'oz', type: string = 'site-map') {
  try {
    const { data } = await axios.get(`${ENDPOINT}/main-page/get-menu?q=${type}`, {
      headers: { language: locale },
    });

    return data;
  } catch (e) {
    console.log(e);
  }
}
