'use client';

import { UsefulLink } from '@/lib/types';
import { CustomLink, ScrollAnimation, Section } from '@/ui';
import { Box, Container, Skeleton } from '@mantine/core';
import Image from 'next/image';
import useSWR from 'swr';
import classnames from './gov-links.module.css';

export function GovLinks() {
  const { data: links, isLoading } = useSWR<UsefulLink[]>(['/authorities/main/get-goverment-link']);

  return isLoading ? (
    <Skeleton w="100%" height={300} />
  ) : links && links?.length > 0 ? (
    <Section className="bg-neutral-200">
      <Container>
        <Box className={classnames.links}>
          {links?.map((link, i) => (
            <ScrollAnimation key={link?.id} delay={i * 0.05}>
              <CustomLink href={link?.url || '/'} target={link?.target} className={classnames.link}>
                {link?.icon ? <Image src={link?.icon} alt="" width={64} height={32} /> : null}
                <h5>{link?.title}</h5>
              </CustomLink>
            </ScrollAnimation>
          ))}
        </Box>
      </Container>
    </Section>
  ) : null;
}
