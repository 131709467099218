import { PollData } from '@/lib/types';
import { Empty, ScrollAnimation } from '@/ui';
import { InfoContent, Poll } from '@/widgets';
import { Flex, Loader } from '@mantine/core';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';

export default function Polls() {
  const t = useTranslations();

  const { data: pollData, isLoading } = useSWR<PollData>([
    '/authorities/vote/get-set-survey',
    { params: { user_agent: typeof window !== 'undefined' ? window?.navigator?.userAgent : '' } },
  ]);

  return (
    <InfoContent
      title={t('So‘rovnomalar')}
      details={{ title: t('Barcha so‘rovnomalar'), link: '/survey' }}
      className="h-full"
      data-bordered="3"
    >
      {isLoading ? (
        <Flex h="100%" justify="center" align="center">
          <Loader />
        </Flex>
      ) : pollData?.data?.question ? (
        <ScrollAnimation className="h-full">
          <Poll question={pollData?.data?.question} />
        </ScrollAnimation>
      ) : (
        <Empty />
      )}
    </InfoContent>
  );
}
