'use client';

import { UsefulLink } from '@/lib/types';
import { Empty, LinkBox, ScrollAnimation } from '@/ui';
import { InfoContent } from '@/widgets';
import { useTranslations } from 'next-intl';
import useSWR from 'swr';

export default function UsefulLinks() {
  const t = useTranslations();

  const { data: links, isLoading } = useSWR<UsefulLink[]>([
    '/authorities/main/get-useful-links',
    { params: { limit: 4 } },
  ]);

  return (
    <InfoContent
      title={t('Foydali havolalar')}
      details={{
        title: t('Barcha havolalar'),
        link: '/usefullinks',
      }}
      data-bordered="3"
    >
      {isLoading ? (
        <LinkBox.Loading count={4} />
      ) : links && links?.length > 0 ? (
        links?.map((service) => (
          <ScrollAnimation key={service?.id} className="basis-20">
            <LinkBox url={service?.url} target="_blank">
              {service?.title}
            </LinkBox>
          </ScrollAnimation>
        ))
      ) : (
        <Empty />
      )}
    </InfoContent>
  );
}
