'use client';

import { Menu } from '@/lib/types';
import { createURL } from '@/lib/utils';
import { CustomLink } from '@/ui';
import { Box, Flex } from '@mantine/core';

export default function Links({ links }: { links: Menu[] }) {
  return (
    <Flex justify="center" wrap="wrap" columnGap="3rem" rowGap=".5rem">
      {links?.map((link) => (
        <Box key={link?.id}>
          <CustomLink
            href={createURL(link?.url)}
            target={link?.target}
            className="font--sm font-medium uppercase hover:underline"
          >
            {link?.title}
          </CustomLink>
        </Box>
      ))}
    </Flex>
  );
}
