'use client';

import { routing, usePathname, useRouter } from '@/i18n/routing';
import { PhoneIcon, SearchIcon } from '@/icons';
import { useHeaderStore } from '@/lib/store';
import { AuthorityInfo, Language, Menu as MenuT } from '@/lib/types';
import { createURL, NAVBAR_Z_INDEX } from '@/lib/utils';
import { removeAuthoritySegment } from '@/lib/utils/helpers';
import { CustomLink } from '@/ui';
import { Accessibility, LanguageSwitcher } from '@/widgets';
import { Box, Container, Flex, Image, Menu, Tooltip, TooltipProps, UnstyledButton } from '@mantine/core';
import { useDisclosure, useElementSize, useMediaQuery } from '@mantine/hooks';
import { useTranslations } from 'next-intl';
import { Suspense, useEffect } from 'react';
import classnames from './header.module.css';
import HeaderSearch from './HeaderSearch';
import Sitemap from './Sitemap';

type Props = {
  info: AuthorityInfo;
  sitemap: MenuT[];
  locale: string;
  locales: Language[];
  children: React.ReactNode;
};

type NavigationItemProps = {
  isHalfPart: boolean;
  item: MenuT;
};

const tooltipOptions = {
  zIndex: NAVBAR_Z_INDEX + 1,
  position: 'bottom',
  color: 'primary',
  withArrow: true,
  arrowSize: 5,
  maw: 220,
  multiline: true,
  className: 'text-center',
  events: { hover: true, focus: true, touch: false },
} as TooltipProps;

export default function HeaderView({ info, sitemap, locale, locales, children }: Props) {
  const t = useTranslations();
  const router = useRouter();
  const pathname = usePathname();
  const setHeaderHeight = useHeaderStore((store) => store.setHeight);
  const [searchOpened, { open: openSearch, close: closeSearch }] = useDisclosure(false);
  const { ref, height } = useElementSize();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isMobileOnServer = useMediaQuery('(max-width: 768px)', true);

  useEffect(() => {
    setHeaderHeight(height);
  }, [height]);

  const updatePage = (href: string) => () => {
    window.location.replace(href);
  };

  useEffect(() => {
    if (!locales.some((l: Language) => l?.code === locale)) {
      router.replace(pathname, { locale: locales?.[0]?.code || routing.defaultLocale });
    }
  }, [locale, locales]);

  const helpline = info?.helpline_value?.split(/[,;]/g)?.filter(Boolean) || [];

  const contact = (
    <>
      <UnstyledButton className={classnames.search} onClick={openSearch} aria-label={t('Qidiruv')}>
        <SearchIcon />
      </UnstyledButton>
      <span className={classnames.phone}>
        <PhoneIcon />
      </span>
      <Flex direction="column">
        {helpline?.length > 0 ? <h3 className={classnames.helpline__title}>{info?.helpline_title}</h3> : null}
        <Flex columnGap="xs" wrap="wrap">
          {helpline?.map((phone) => (
            <CustomLink key={phone} href={`tel:${phone?.trim()}`} className={classnames.helpline__number}>
              {phone?.trim()};
            </CustomLink>
          ))}
        </Flex>
        <CustomLink href={`/pages/contacts`} className={classnames.helpline__contact}>
          {info?.contact_title}
        </CustomLink>
      </Flex>
      <Tooltip {...tooltipOptions} label={t('Tilni tanlang')}>
        <div>
          <LanguageSwitcher locale={locale} locales={locales} />
        </div>
      </Tooltip>
    </>
  );

  return (
    <>
      <header className={classnames.header} ref={ref}>
        <Container size="100rem" className="relative flex flex-col">
          <Flex justify="space-between" align="center" gap="xs" className="py-6 pr-0 3xl:py-3 lg:pr-[34px]">
            <Flex component={CustomLink} onClick={updatePage('/')} href="/" align="center" gap="md">
              <Image
                src={info?.logo}
                w={{ base: 52, xs: 64, '3xl': 100 }}
                h={{ base: 52, xs: 64, '3xl': 100 }}
                alt="Logo"
                className="object-contain"
              />
              <h2 className={classnames.title}>{info?.title}</h2>
            </Flex>
            <Box>
              {isMobile ? null : (
                <Flex align="center" gap={{ base: 'sm', '2xl': 'lg' }} className="shrink-0 lg:hidden">
                  {contact}
                  <Accessibility />
                </Flex>
              )}
              <Sitemap data={sitemap} isMobile />
            </Box>
          </Flex>
          {isMobileOnServer ? (
            <Flex justify="center" align="center" gap="lg" className="hidden shrink-0 pb-3 lg:flex">
              {contact}
              <Accessibility />
            </Flex>
          ) : null}
          {isMobile ? null : (
            <Box className={classnames.header__navigation}>
              <Flex justify="center" wrap="wrap" px="md" className={classnames.header__menu}>
                {sitemap?.length > 0
                  ? sitemap?.map((item, i) => (
                      <NavigationItem key={item?.id} isHalfPart={i > sitemap.length / 2} item={item} />
                    ))
                  : null}
              </Flex>
              <Sitemap data={sitemap} />
            </Box>
          )}
        </Container>
      </header>
      <Suspense>
        <HeaderSearch opened={searchOpened} close={closeSearch}>
          {children}
        </HeaderSearch>
      </Suspense>
    </>
  );
}

function NavigationItem({ isHalfPart, item }: NavigationItemProps) {
  const pathname = usePathname();
  const isMenuActive = item?.children?.some((child) => removeAuthoritySegment(`/${child?.url}`) === pathname);

  return (
    <Menu
      key={item?.id}
      position={isHalfPart ? 'bottom-end' : 'bottom-start'}
      trigger="click-hover"
      loop={false}
      withinPortal={false}
      menuItemTabIndex={0}
      offset={0}
      closeDelay={0}
      classNames={classnames}
    >
      <Menu.Target>
        <UnstyledButton className={classnames.menu__item} data-active={isMenuActive}>
          {item?.title}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown bg="primary">
        {item?.children?.map((child) => (
          <Menu.Item
            key={child?.id}
            component={CustomLink}
            target={child?.url?.includes('http') ? '_blank' : '_self'}
            href={createURL(child?.url)}
            data-active={removeAuthoritySegment(`/${child?.url}`) === pathname}
          >
            {child?.title}
          </Menu.Item>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
}
